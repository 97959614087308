import { useSelector } from "react-redux";
import PoiService from "./PoiService";

class PoiTabsService {
  poiService = new PoiService();

  detailsTabSelector = useSelector((state) => state.detailTab.value);
  deliveryBoundarySelector = useSelector(
    (state) => state.deliveryBoundary.value
  );
  assignedTeamSelector = useSelector((state) => state.assignedTeams.value);
  deliveryAreaSelector = useSelector((state) => state.deliveryAreas.value);
  configTabSelector = useSelector((state) => state.configTab.value);
  poiListSelector = useSelector((state) => state.poiLists.value);
  user = useSelector((state) => state.userInfo.value);

  prepareSaveDataForAddEdit = () => {
    return {
      area_manager_name: this.detailsTabSelector.area_manager_name,
      area_manager_phone: this.detailsTabSelector.area_manager_phone,
      brand_ids: this.detailsTabSelector.brand_ids
        .map((brand) => brand.id)
        .join(),
      bulding_nr: this.detailsTabSelector.bulding_nr,
      city_id: this.detailsTabSelector.city_id.id,
      client_id: this.detailsTabSelector.client_id.id,
      contact_email: this.detailsTabSelector.contact_email,
      contact_person: this.detailsTabSelector.contact_person,
      contact_phone: this.detailsTabSelector.contact_phone,
      country_id: this.detailsTabSelector.country_id.id,
      edge_points: this.deliveryBoundarySelector
        ? this.poiService.prepareEdgePoints(this.deliveryBoundarySelector)
        : "",
      latitude: this.detailsTabSelector.latitude
        ? Number(this.detailsTabSelector.latitude)
        : this.poiService.getLatLng().lat,
      longitude: this.detailsTabSelector.longitude
        ? Number(this.detailsTabSelector.longitude)
        : this.poiService.getLatLng().lng,
      name: this.detailsTabSelector.name,
      operation_zone: this.detailsTabSelector.operation_zone,
      order_type: this.detailsTabSelector.order_type,
      zip_code: this.detailsTabSelector.zip_code,
      store_manager_phone: this.detailsTabSelector.store_manager_phone,
      street: this.detailsTabSelector.street,
      currency: this.detailsTabSelector.currency,
      timezone: this.detailsTabSelector.timezone,
      open_t: this.detailsTabSelector.open_t,
      close_t: this.detailsTabSelector.close_t,
      logo: this.detailsTabSelector.logo,
      go_live_date: this.detailsTabSelector.golivedate,
      postal_code: this.detailsTabSelector.postal_code,
      teams: this.assignedTeamSelector
        .filter(
          (checkData) => checkData.team_type && checkData.team_type !== "NONE"
        )
        .map((data) => ({
          team_id: data.id,
          team_type: data.team_type,
        })),
      delivery_areas: this.deliveryAreaSelector
        .filter(
          (checkData) =>
            checkData.connection_name && (checkData.id || checkData.zone_id)
        )
        .map((data) => ({
          zone_id: data.id || data.zone_id,
          connection_name: data.connection_name,
        })),
      track_api_new: this.configTabSelector.track_api_new,
      track_share_eta: this.configTabSelector.track_share_eta,
      return_track_id: this.configTabSelector.return_track_id,
      number_gln: this.configTabSelector.number_gln
        ? this.configTabSelector.number_gln
        : "",
      upfront_payment_by_driver:
        this.configTabSelector.upfront_payment_by_driver,
      Location_Algorithm_2: this.configTabSelector.Location_Algorithm_2,
      is_convert_address: this.configTabSelector.is_convert_address,
      order_acceptance_check: this.configTabSelector.order_acceptance_check,
      Check_for_Delivery_Boundary:
        this.configTabSelector.order_acceptance_check === "DELIVERY_BOUNDARY",
      Check_for_Delivery_Area:
        this.configTabSelector.order_acceptance_check === "DELIVERY_AREA",
      Check_for_Team_Boundary:
        this.configTabSelector.order_acceptance_check === "TEAM_BOUNDARY",
      order_fields: this.prepareOrderFields(),
      start_tracking: this.configTabSelector.start_tracking,
      dod_request_time: this.configTabSelector.dod_request_time,
      time_to_switch_delivery_partner:
        this.configTabSelector.time_to_switch_delivery_partner,
      dod_withdrawal: this.configTabSelector.dod_withdrawal,
      target_dod_arrival_time: this.configTabSelector.target_dod_arrival_time,
      dod_target_assign: this.configTabSelector.dod_target_assign,
      distance_app: this.prepareDistanceAppFields(),
      eta_for_broadcast: this.configTabSelector.eta_for_broadcast,
      is_show_waiting_eta: this.configTabSelector.is_show_waiting_eta,
      is_show_return_eta: this.configTabSelector.is_show_return_eta,
      is_show_reach_eta: this.configTabSelector.is_show_reach_eta,
      orders_reach_eta: this.configTabSelector.orders_reach_eta,
      orders_reach_dod_eta: this.configTabSelector.orders_reach_dod_eta,
      eta_tracking_url: this.configTabSelector.eta_tracking_url,
      route_tracking_url: this.configTabSelector.route_tracking_url,
      is_new_autoassign:
        this.configTabSelector.is_new_autoassign === "v2.0" ? true : false,
      is_autoassign_v1:
        this.configTabSelector.is_new_autoassign === "v1.0" ? true : false,
      is_scan_orders: this.configTabSelector.is_scan_orders,
      radius: this.configTabSelector.radius,
      arrived_for_pickup_radius:
        this.configTabSelector.arrived_for_pickup_radius,
      enable_pickup_after: this.configTabSelector.enable_pickup_after,
      is_photo_on_pickup: this.configTabSelector.is_photo_on_pickup,
      is_photo: this.configTabSelector.is_photo,
      is_emirateId: this.configTabSelector.is_emirateId,
      is_signature: this.configTabSelector.is_signature,
      is_return: this.configTabSelector.is_return,
      is_show_waiting: this.configTabSelector.is_show_waiting,
      sos_compliance: this.configTabSelector.sos_compliance,
      sos_compared_against: this.configTabSelector.sos_compared_against,
      arrived_for_pickup_check: this.configTabSelector.arrived_for_pickup_check,
      zc_waiting_orders: this.configTabSelector.zc_waiting_orders,
      is_delivery_partner_auto_assignment_enabled:
        this.configTabSelector.is_delivery_partner_auto_assignment_enabled,
      compliance_pickup: this.configTabSelector.compliance_pickup,
      compliance_delivery: this.configTabSelector.compliance_delivery,
      is_cash_center: this.configTabSelector.is_cash_center,
      is_force_del_area: this.detailsTabSelector.is_force_del_area,
      expected_sos_start: JSON.stringify(this.configTabSelector.startSOS),
      expected_sos_end: JSON.stringify(this.configTabSelector.endSOS),
      target_assign: this.configTabSelector.target_assign,
      target_order: this.configTabSelector.target_order,
      target_wait: this.configTabSelector.target_wait,
      target_customer: this.configTabSelector.target_customer,
      target_ins: this.configTabSelector.target_ins,
      target_drive: this.configTabSelector.target_drive,
      is_dp_enabled: this.configTabSelector.is_dp_enabled,
      target_sos: this.configTabSelector.target_sos,
      aggregators: this.configTabSelector.aggregator
        ? this.configTabSelector.aggregator
            .map((list) => list.aggregator)
            .join()
        : "",
      user_id: this.user.id,
      internal_driver_search_timeout:
        this.configTabSelector.internal_driver_search_timeout,
    };
  };

  prepareDpConfigChanges = () => {
    const dpConfig = this.configTabSelector.multiEditDp;
    let dp_config = {
      ...(dpConfig.is_delivery_partner_auto_assignment_enabled.checked && {
        is_delivery_partner_auto_assignment_enabled: this.configTabSelector.is_delivery_partner_auto_assignment_enabled  ?? false
      }),
      ...(dpConfig.is_dp_enabled.checked && {
        is_dp_enabled: this.configTabSelector.is_dp_enabled ?? false
      }),
      ...(dpConfig.dod_request_time.checked && {
        dod_request_time: this.configTabSelector.dod_request_time
      }),
      ...(dpConfig.target_dod_arrival_time.checked && {
        target_dod_arrival_time: this.configTabSelector.target_dod_arrival_time ?? ''
      }),
      ...(dpConfig.dod_target_assign.checked && {
        dod_target_assign: this.configTabSelector.dod_target_assign ?? ''
      })
    };

    return dp_config;
  };

  prepareDataForMultiEdit = () => {

    const dpConfigExist =
        Object.values(this.configTabSelector.multiEditDp)?.filter(
            (item) => item.checked
        ) || [];


    let dp_changes = {};
    if (dpConfigExist.length > 0) {
      dp_changes = this.prepareDpConfigChanges();
    }

    return {
      track_api_new: this.configTabSelector.track_api_new,
      track_share_eta: this.configTabSelector.track_share_eta,
      return_track_id: this.configTabSelector.return_track_id,
      upfront_payment_by_driver:
        this.configTabSelector.upfront_payment_by_driver,
      Location_Algorithm_2: this.configTabSelector.Location_Algorithm_2,
      is_convert_address: this.configTabSelector.is_convert_address,
      order_acceptance_check: this.configTabSelector.order_acceptance_check,
      order_fields: this.prepareOrderFields(),
      start_tracking: this.configTabSelector.start_tracking,
      time_to_switch_delivery_partner: this.configTabSelector.time_to_switch_delivery_partner,
      dod_withdrawal: this.configTabSelector.dod_withdrawal,
      distance_app: this.prepareDistanceAppFields(),
      eta_for_broadcast: this.configTabSelector.eta_for_broadcast,
      is_show_waiting_eta: this.configTabSelector.is_show_waiting_eta,
      is_show_return_eta: this.configTabSelector.is_show_return_eta,
      is_show_reach_eta: this.configTabSelector.is_show_reach_eta,
      orders_reach_eta: this.configTabSelector.orders_reach_eta,
      orders_reach_dod_eta: this.configTabSelector.orders_reach_dod_eta,
      eta_tracking_url: this.configTabSelector.eta_tracking_url,
      route_tracking_url: this.configTabSelector.route_tracking_url,
      is_new_autoassign: this.configTabSelector.is_new_autoassign,
      is_scan_orders: this.configTabSelector.is_scan_orders,
      radius: this.configTabSelector.radius,
      arrived_for_pickup_radius:
        this.configTabSelector.arrived_for_pickup_radius,
      enable_pickup_after: this.configTabSelector.enable_pickup_after,
      is_photo_on_pickup: this.configTabSelector.is_photo_on_pickup,
      is_photo: this.configTabSelector.is_photo,
      is_emirateId: this.configTabSelector.is_emirateId,
      is_signature: this.configTabSelector.is_signature,
      is_return: this.configTabSelector.is_return,
      is_show_waiting: this.configTabSelector.is_show_waiting,
      sos_compliance: this.configTabSelector.sos_compliance,
      sos_compared_against: this.configTabSelector.sos_compared_against,
      arrived_for_pickup_check: this.configTabSelector.arrived_for_pickup_check,
      zc_waiting_orders: this.configTabSelector.zc_waiting_orders,
      compliance_pickup: this.configTabSelector.compliance_pickup,
      compliance_delivery: this.configTabSelector.compliance_delivery,
      is_cash_center: this.configTabSelector.is_cash_center,
      is_force_del_area: this.detailsTabSelector.is_force_del_area,
      expected_sos_start: JSON.stringify(this.configTabSelector.startSOS),
      expected_sos_end: JSON.stringify(this.configTabSelector.endSOS),
      target_assign: this.configTabSelector.target_assign,
      target_order: this.configTabSelector.target_order,
      target_wait: this.configTabSelector.target_wait,
      target_customer: this.configTabSelector.target_customer,
      target_ins: this.configTabSelector.target_ins,
      target_drive: this.configTabSelector.target_drive,
      target_sos: this.configTabSelector.target_sos,
      aggregators: this.configTabSelector.aggregator
        ? this.configTabSelector.aggregator
            .map((list) => list.aggregator)
            .join()
        : "",
      ...dp_changes,
      user_id: this.user.id,
    };
  };

  prepareOrderFields = () => {
    if (
      this.configTabSelector.order_fields.DELIVERY_AREA &&
      this.configTabSelector.order_fields.DESTINATION_LAT_LONG
    ) {
      return "DELIVERY_AREA,DESTINATION_LAT_LONG";
    } else {
      if (this.configTabSelector.order_fields.DELIVERY_AREA) {
        return "DELIVERY_AREA";
      }
      if (this.configTabSelector.order_fields.DESTINATION_LAT_LONG) {
        return "DESTINATION_LAT_LONG";
      }
    }
  };

  prepareDistanceAppFields = () => {
    if (
      this.configTabSelector.distance_app.ACTUAL &&
      this.configTabSelector.distance_app.PLANNED
    ) {
      return "Actual,Planned";
    } else {
      if (this.configTabSelector.distance_app.ACTUAL) {
        return "Actual";
      }
      if (this.configTabSelector.distance_app.PLANNED) {
        return "Planned";
      }
    }
  };

  getTabsList = () => {
    let tempArr = [];

    if (this.poiListSelector.viewType === "add") {
      if (
        this.poiListSelector.selectedAccount &&
        this.poiListSelector.selectedAccount.id &&
        this.detailsTabSelector.city_id &&
        this.detailsTabSelector.city_id.id &&
        this.detailsTabSelector.country_id &&
        this.detailsTabSelector.country_id.id
      ) {
        tempArr = [
          { idx: 0, name: "Details", isDisabled: false },
          { idx: 1, name: "Configuration", isDisabled: false },
          { idx: 2, name: "Assigned Teams", isDisabled: false },
          { idx: 3, name: "Delivery Area/Boundary", isDisabled: false },
        ];
      } else {
        tempArr = [
          { idx: 0, name: "Details", isDisabled: false },
          { idx: 1, name: "Configuration", isDisabled: true },
          { idx: 2, name: "Assigned Teams", isDisabled: true },
          { idx: 3, name: "Delivery Area/Boundary", isDisabled: true },
        ];
      }
    } else {
      if (this.user.permissions) {
        if (
          this.user.permissions.poi_viewPoiDetails ||
          this.user.permissions.poi_editPoiDetails
        ) {
          tempArr.push({ idx: 0, name: "Details", isDisabled: false });
        }
        if (
          this.user.permissions.poi_viewPoiConfiguration ||
          this.user.permissions.poi_editPoiConfiguration ||
          this.user.permissions.poi_viewDeliveryPartnerConfiguration
        ) {
          tempArr.push({ idx: 1, name: "Configuration", isDisabled: false });
        }
        if (
          this.user.permissions.poi_viewAssignedTeamsTab ||
          this.user.permissions.poi_editAssignedTeamsTab
        ) {
          tempArr.push({ idx: 2, name: "Assigned Teams", isDisabled: false });
        }
        if (
          this.user.permissions.poi_editDeliveryAreaTab ||
          this.user.permissions.poi_viewDeliveryAreaTab ||
          this.user.permissions.poi_editDeliveryBoundaryTab ||
          this.user.permissions.poi_viewDeliveryBoundaryTab
        ) {
          tempArr.push({
            idx: 3,
            name: "Delivery Area/Boundary",
            isDisabled: false,
          });
        }
      }
    }

    return tempArr;
  };
}

export default PoiTabsService;
